body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Blinking cursor animation */
@keyframes blink {
  0% {
    border-right: 2px solid transparent; /* Transparent border at the start */
  }
  50% {
    border-right: 2px solid black; /* Solid black border in the middle */
  }
  100% {
    border-right: 2px solid transparent; /* Transparent border at the end */
  }
}

/* Apply the animation to the input field */
.blinking-cursor::before {
  content: '';
  display: inline-block;
  width: 2px; /* Width of the cursor */
  height: 1.2em; /* Height of the cursor (adjust as needed) */
  background-color: black; /* Color of the cursor */
  animation: blink 1s step-end infinite; /* Use the 'blink' animation */
  margin-right: 3px; /* Adjust the distance between the cursor and text */
}






