/* src/styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {

    .clip-style {
 
        clip-path: polygon(0 3%,100% 0,100% 100%,0 101%);
        -webkit-clip-path: polygon(0 3%,100% 0,100% 100%,0 101%);
 
    }
 
 }